<template>
    <div>
        <van-tabbar v-model="active">
            <van-tabbar-item>
                <span>视频</span>
                <template #icon="props">
                <img :src="props.active ? 'http://www.rage.chnyanwei.com/img/icon_1.png' : 'http://www.rage.chnyanwei.com/img/icon_1_un.png' "/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item @click="goinfo('inquiry')">
                <span>问诊</span>
                <template #icon="props">
                <img :src="props.active ? 'http://www.rage.chnyanwei.com/img/icon_2.png' : 'http://www.rage.chnyanwei.com/img/icon_2_un.png' "/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item @click="goinfo('pharmacy')">
                <span>药房</span>
                <template #icon="props">
                <img :src="props.active ? 'http://www.rage.chnyanwei.com/img/icon_4.png' : 'http://www.rage.chnyanwei.com/img/icon_4_un.png' "/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item @click="goinfo('my')">
                <span>我的</span>
                <template #icon="props">
                <img :src="props.active ? 'http://www.rage.chnyanwei.com/img/icon_3.png' : 'http://www.rage.chnyanwei.com/img/icon_3_un.png' "/>
                </template>
            </van-tabbar-item>
        </van-tabbar>

        
    </div>
</template>

<script>
export default {
    props:{
        active: {
            default: 0,
            type: Number
        }
    },

    methods:{
        goinfo(names){
            this.$router.push({name: names})
        }
    }
}
</script>

<style lang="less" scoped>

</style>
