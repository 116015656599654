<template>
  <div class="bg">
    <div class="top">
      <div class="imgbox">
        <img
          src="https://oimagec8.ydstatic.com/image?id=-875241627009222359&product=adpublish&format=JPEG&w=520&h=347"
          alt=""
          class=""
          @click="goinfo('myinfo')"
        />
        <div>
          <p class="f18">张霖霖</p>
          <span class="f14">15966668888</span>
        </div>
      </div>
      <img src="../../assets/img/set.png" alt="" class="set" @click="goinfo('set')" />
    </div>
    <div class="bot">
      <div class="bot_1">
        <div @click="goinfo('reception')">
          <van-badge :content="5">
            <img src="../../assets/img/my_1.png" alt="" />
          </van-badge>
          <p>待接接诊</p>
        </div>
        <div @click="goinfo('myinquiry')">
          <van-badge :content="5">
            <img src="../../assets/img/my_2.png" alt="" />
          </van-badge>
          <p>我的问诊</p>
        </div>
        <div @click="goinfo('mydoctor')">
          <van-badge :content="5">
            <img src="../../assets/img/my_3.png" alt="" />
          </van-badge>
          <p>我的医生</p>
        </div>
      </div>
      <div class="bot_2">
        <h3>
          我的订单
          <span @click="goinfo('orderfrom')"
            >全部订单 <img src="../../assets/img/right.png" alt=""
          /></span>
        </h3>
        <div class="flex">
          <div @click="goinfo('orderfrom', 0)">
            <van-badge :content="5">
              <img src="../../assets/img/my_4.png" alt="" />
            </van-badge>

            <p>待付款</p>
          </div>
          <div @click="goinfo('orderfrom', 1)">
            <van-badge :content="5">
              <img src="../../assets/img/my_5.png" alt="" />
            </van-badge>
            <p>待发货</p>
          </div>
          <div @click="goinfo('orderfrom'), 2">
            <van-badge :content="5">
              <img src="../../assets/img/my_6.png" alt="" />
            </van-badge>
            <p>待收货</p>
          </div>
          <div @click="goinfo('orderfrom', 3)">
            <van-badge :content="5">
              <img src="../../assets/img/my_7.png" alt="" />
            </van-badge>
            <p>已完成</p>
          </div>
        </div>
      </div>
      <div class="bot_3 cl">
        <div class="div">
          <van-badge :content="5">
            <img src="../../assets/img/my_8.png" alt="" />
          </van-badge>
          <p>我的基金</p>
        </div>
        <div class="div" @click="goinfo('collect')">
          <van-badge :content="5">
            <img src="../../assets/img/my_9.png" alt="" />
          </van-badge>
          <p>我的收藏</p>
        </div>
        <div class="div">
          <van-badge :content="5">
            <img src="../../assets/img/my_10.png" alt="" />
          </van-badge>
          <p>联系客服</p>
        </div>
        <div class="div" @click="goinfo('help')">
          <van-badge :content="5">
            <img src="../../assets/img/my_11.png" alt="" />
          </van-badge>
          <p>我的求助</p>
        </div>
        <div class="div" @click="goinfo('hitdistribution')">
          <van-badge :content="5">
            <img src="../../assets/img/my_12.png" alt="" />
          </van-badge>
          <p>我的急配</p>
        </div>
        <div class="div" @click="goinfo('message')">
          <van-badge :content="5">
            <img src="../../assets/img/my_13.png" alt="" />
          </van-badge>
          <p>我的消息</p>
        </div>
        <div class="div" @click="goinfo('myaddress')">
          <van-badge :content="5">
            <img src="../../assets/img/my_12.png" alt="" />
          </van-badge>
          <p>我的地址</p>
        </div>
      </div>
    </div>
    <navbar :active="3"></navbar>
  </div>
</template>
<script>
import navbar from '@/components/common/nav'
export default {
  components:{
    navbar
  },
  data() {
    return {};
  },
  methods: {
    goinfo(names, index) {
      console.log(index)
      if (index != undefined) {
  
        this.$router.push({ name: names,params:{active:index} });
      } else {
        this.$router.push({ name: names });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  // height: 100%;
  height: 100vh;
  background: #f5f5f5;
  position: relative;
  .top {
    height: 200px;
    background: linear-gradient(180deg, #1daefc, #1de5fc);
    position: relative;
    .set {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 25px;
      right: 16px;
    }
    .imgbox {
      display: flex;
      text-align: left;
      color: rgba(255, 255, 255, 1);
      padding: 25px 0 0 16px;
      .f18 {
        margin-top: 13px;
        margin-bottom: 8px;
      }
      img {
        width: 80px;
        height: 80px;
        display: block;
        border-radius: 50%;
        margin-right: 16px;
      }
    }
  }
  .bot {
    position: absolute;
    padding: 0 16px;
    top: 128px;
    .bot_1 {
      margin-bottom: 8px;
      width: 343px;
      height: 99px;
      background: #ffffff;
      border-radius: 8px;
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      padding: 30px 0 16px 0;
      img {
        display: inline-block;
        width: 32px;
        height: 32px;
      }
    }
    .bot_2 {
      width: 343px;
      height: 154px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 8px;
      h3 {
        padding: 14px 0;
        text-align: left;
        font-weight: 400;
        color: #2d2d2d;
        border-bottom: 1px solid #efefef;
        margin-bottom: 16px;
        span {
          float: right;
          font-size: 12px;
          color: rgba(194, 194, 194, 1);
          img {
            width: 6px;
            height: 11px;
            margin-left: 5px;
          }
        }
      }
      .flex {
        display: flex;
        justify-content: space-between;
        text-align: center;
      }
      padding: 0 13px;
      img {
        display: inline-block;
        width: 48px;
        height: 48px;
      }
    }
    .bot_3 {
      margin-bottom: 8px;
      width: 343px;
      height: 162px;
      background: #ffffff;
      border-radius: 8px;

      text-align: center;
      padding: 16px 24px;
      .div {
        margin-left: 20px;
        margin-bottom: 24px;
        float: left;
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
        // &:nth-child(n+4) {
        //   margin-left: 0;
        // }
      }
      img {
        display: inline-block;
        width: 32px;
        height: 32px;
      }
    }
  }
}
</style>
